import React, { useState } from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import TContrib from "../components/layout/multiLanguage/translateContrib"
import styled from "styled-components"
import { languages } from "../../config/config"
import { usePageContext } from "../components/provider/pageContext"
import { color, fontSize } from "../components/provider/theme"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const StyledExpo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${fontSize(`large`)};
  text-transform: uppercase;
`

const StyledDate = styled.div`
  flex: 1;
`

const StyledText = styled.div`
  flex: 3;
  color: ${color(`grey`)};

  p:first-of-type {
    margin-top: 0;
  }
`

function Expo({ expo }) {
  const [date] = useState(() => new Date(expo.date))
  const { lang = languages.default } = usePageContext()
  const fr = lang === languages.default
  return (
    <StyledExpo>
      <StyledDate>
        {expo.full_date
          ? `${fr ? date.getDay() : date.getMonth()}/${
              !fr ? date.getDay() : date.getMonth()
            }/${date.getFullYear()}`
          : date.getFullYear()}
      </StyledDate>
      <StyledText>
        <TContrib data={expo} field="texte" />
      </StyledText>
    </StyledExpo>
  )
}

export default function Expositions({
  data: {
    allPrismicExposition: { nodes },
  },
  ...props
}) {
  return (
    <Layout title="Expositions" {...props}>
      <Container>
        {nodes.map(expo => (
          <Expo expo={expo.data} key={expo.id} />
        ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicExposition(sort: { order: DESC, fields: data___date }) {
      nodes {
        data {
          date
          full_date
          texte_fr {
            html
          }
          texte_en {
            html
          }
        }
      }
    }
  }
`
